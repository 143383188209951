@media screen and (min-width: 601px) {
  .form {
    margin: 20px;
    margin-left: 20%;
    width: 60%;
  }
}
@media screen and (min-width: 901px) {
  .form {
    margin: 20px;
    margin-left: 25%;
    width: 50%;
  }
}
@media screen and (min-width: 1000px) {
  .form {
    margin: 20px;
    margin-left: 30%;
    width: 40%;
  }
}
@media screen and (max-width: 600px) {
  .form {
    margin-left: 1%;
    width: 90%;
    margin: 20px;
  }
}
.form {
  border: 1px solid #eee;
  margin-top: 2%;
  box-shadow: 3px 3px 3px #eee;
  padding: 10px;
  border-radius:5px;
  background-color: #ffff;
  input {
    background-color: #ffff;
    border: 1px solid #eee;
    padding: 15px;
  }
}
#submit-btn {
  background-color: #041122;
  color: #d9e5f5;
  font-weight: 600;
  font-size: 18px;
  border: none;
}
.services-card {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #ffff;
    text-decoration: underline 3px #f10000;
  }
  h3 {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: #031733;
  }
}
.instruction {
  color: #920f0f;
  font-size: 14px;
}
.spinner {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 40vh;
  h2 {
    color: #1d3557;
    margin: 3px;
    font-style: italic;
    @media screen and (max-width: 600px) {
      font-size: 17px;
    }
    @media screen and (max-width: 350px) {
      font-size: 14px;
    }
  }
  }
.signin-success {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 15%;
  width: 70%;
  margin-top: 2vh;
  @media screen and (max-width: 600px) {
    margin-left: 8%;
    width: 85%;
  }
}
.services-card h3 {
  @media screen and (max-width: 600px) {
    font-size: 17px;
  }
}
