body{
    background-color: #fcfcfc;
}
.form-login{
    background-color: #ffff;
    border: 1px;
    box-shadow: 3px 3px 3px #eee;
    padding: 10px;
    border-radius: 10px;
    input{
        background-color: #ffff;
        border: 1px solid #eee;
        padding: 15px;
    }
}

@media screen and (min-width:601px){
    .form-login{
       margin:20px;
       margin-left: 20%;
       width:60%;
       margin-top: 11%;
    }
   }
   @media screen and (min-width:901px){
    .form-login{
       margin:20px;
       margin-left: 25%;
       width:50%;
       margin-top: 8%;
    }
   }
   @media screen and (min-width:1000px){
    .form-login{
       margin-left: 32%;
       width:35%;
    }
   }
   @media screen and (max-width:600px){
    .form-login{
        margin:20px;
       margin-left: 10%;
       width:82%;
       margin-top: 15%;
    }
   }
   @media screen and (max-width:350px){
    .form-login{
       margin-left: 6%;
       width:90%;
       margin-top: 15%;
    }
   }
   @media screen and (min-width:500px) and (max-width:600px) {
    .form-login{
        margin-left: 10%;
        width:82%;
        margin-top: 15%;
     }
   }

#submit{
    background-color: #1d3557;
}
.services-card{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        color: #ffff;
        text-decoration: underline 1px #920f0f;
    }
}
.instruction{
    color: #920f0f;
    font-size: 14px;
}
.spinner{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 40vh;
    h2{
        color: #1d3557;
        margin: 3px;
        font-style: italic;
        @media screen and (max-width:600px) {
            font-size: 17px;
        }
        @media screen and (max-width:350px) {
            font-size: 14px;
        }
    }
}
.signin-success{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 15%;
    width: 70%;
    margin-top: 2vh;
    @media screen and (max-width:600px) {
        margin-left: 8%;
        width: 85%;
    }
}
.services-card h3{
    @media screen and (max-width:600px) {
        font-size: 17px;
    }
}
.other-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
    .a{
        color: #1d3557;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }
    .a:last-child{
        color: #920f0f;
    }
}
#submit{
    width: 82%;
    padding: 10px;
    box-shadow: 1px 1px 1px #eee;
    border: none;
    background-color: #041122;
    margin-left: 8%;
    font-weight: 600;
    font-size: 20px;
    color: #d9e5f5;
}
.other-link-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
    .a{
        color: #1d3557;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        margin-top: 1px;
    }
}